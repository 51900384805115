import React from "react";
import "./App.css";

import { IconContext } from "react-icons";
import { BiWater } from "react-icons/bi";
import { FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TbBuildingFactory, TbBuildingFactory2 } from "react-icons/tb";
import { AiOutlineControl } from "react-icons/ai";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

//const geoUrl =
//  "https://raw.githubusercontent.com/danielcs88/fl_geo_json/master/fl-state.json";
const geoUrl =
  "https://github.com/deldersveld/topojson/blob/master/countries/united-states/lower-quality-20m/20m-US-counties.json?raw=true";

function App() {
  return (
    <div className="App">
      <header>
        <center>
          <h1 className="company">Lewis &amp; Associates</h1>
          <small>Industrial instrumentation and control systems</small>
          <hr />
          <section>
            <IconContext.Provider value={{ className: "howdy", size: "70" }}>
              <TbBuildingFactory2 className="icon-yellow" />
              <TbBuildingFactory className="icon-blue" />
              <BiWater className="icon-red" />
              <AiOutlineControl className="icon-orange" />
            </IconContext.Provider>
          </section>
        </center>
      </header>
      <article className="content">
        <section>
          <p>
            <span className="tagline">
              We provide process instrumentation to Florida's industries.
            </span>{" "}
            Our products include analytical instrumentation, recorders, data
            acquisition, contollers, sensors, switches and transmitters for
            process variables. Value added services can be provided for our
            products if needed.
          </p>
        </section>
        <section>
          <p>
            <span className="tagline">
              We have been in business for over 40 years.
            </span>{" "}
            We are engineers with extensive experience in the correct
            application of our products across industries.
          </p>
        </section>
        <section>
          <p>
            <span className="tagline">
              We are centrally located NE of Tampa, Florida.
            </span>{" "}
            Our office is in the Thonotosassa community, near the intersection
            of I-75 & I-4. Our office is 1 mile E of I-75 at the Fowler Ave
            (SR-582) exit (Exit 265)
          </p>
        </section>
        <section className="contact">
          <IconContext.Provider
            value={{
              color: "#B6CDB1",
              size: "50",
            }}
          >
            <div className="contact-type">
              <MdEmail />
              <a className="contact-value" href="mailto:sales@la-fl.com">
                sales@la-fl.com
              </a>
            </div>
            <div className="contact-type">
              <FaPhone />
              <a className="contact-value" href="tel:+18139868450">
                +1 813 986 8450
              </a>
            </div>
            <div className="contact-type">
              <FaMapMarkerAlt />
              <address className="contact-value">
                11481 N US Highway 301
                <br />
                Thonotosassa, FL 33592
              </address>
            </div>
          </IconContext.Provider>
        </section>
        <section>
          <center>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1898.930297397959!2d-82.3305458174717!3d28.052417644212014!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c9bb0048061b%3A0xe9d5bc0a39db2d67!2sLewis%20%26%20Associates!5e0!3m2!1sen!2sus!4v1676831431311!5m2!1sen!2sus"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="aasfd"
            />
          </center>
        </section>
        <section>
          <ComposableMap
            projection="geoAzimuthalEqualArea"
            projectionConfig={{
              rotate: [58, 20, 0],
              scale: 400,
            }}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) => {
                return geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#EAEAEC"
                    stroke="#D6D6DA"
                  />
                ));
              }}
            </Geographies>
          </ComposableMap>
        </section>
      </article>
      <footer>
        <span style={{ marginRight: "0.5em" }}>
          &copy; {new Date().getFullYear()}
        </span>
        <span className="company">Lewis &amp; Associates</span>
      </footer>
    </div>
  );
}

export default App;
